import * as types from "../reducers/types";

export const addRLMUser = (payload) => {
  return {
    type: types.ADD_RLMUSER_REQ,
    payload: payload,
  };
};

export const createdUserInfo = (payload) => {
  return {
    type: types.ADD_RLMUSER_RES,
    payload: payload,
  };
};

export const checkUserInfo = (payload) => {
  return {
    type: types.RLMUSER_LOGIN_REQ,
    payload: payload,
  };
};

export const loggedInUserInfo = (payload) => {
  return {
    type: types.RLMUSER_LOGIN_RES,
    payload: payload,
  };
};
export const loggedOutUser = (payload) => {
  return {
    type: types.LOGGEDOUT_USER_REQ,
    payload: payload,
  };
};

export const loggedInUserCourses = (userInfo) => {
  return {
    type: types.USER_ASSIGNED_COURSES_REQ,
    user: userInfo,
  };
};

export const assignedCoursesResponse = (payload) => {
  return {
    type: types.USER_ASSIGNED_COURSES_RES,
    payload: payload,
  };
};

export const setAssignedCourses = (payload) => {
  return {
    type: types.COURSE_ASSIGNMENT_RES,
    payload: payload,
  };
};
export const setAssignedUsers = (payload) => {
  return {
    type: types.USER_COURSE_ASSIGNMENT_RES,
    payload: payload,
  };
};

export const getAllCoursesRequest = (data) => {
  return {
    type: types.ALL_COURSES_REQ,
    payload: data,
  };
};

export const allCoursesResponse = (data) => {
  return {
    type: types.ALL_COURSES_RES,
    payload: data,
  };
};

export const getAllUsersRequest = (data) => {
  return {
    type: types.ALL_USERS_REQ,
    payload: data,
  };
};

export const allUsersResponse = (data) => {
  return {
    type: types.ALL_USERS_RES,
    payload: data,
  };
};

export const getAllAdminAclRequest = (data) => {
  return {
    type: types.ADMIN_ACL_REQ,
    payload: data,
  };
};

export const adminAclResponse = (data) => {
  return {
    type: types.ADMIN_ACL_RES,
    payload: data,
  };
};

export const courseAssignmentRequest = (data) => {
  return {
    type: types.COURSE_ASSIGNMENT_REQ,
    payload: data,
  };
};
export const userCourseAssignmentRequest = (data) => {
  return {
    type: types.USER_COURSE_ASSIGNMENT_REQ,
    payload: data,
  };
};

export const AssignCourses = (payload) => {
  return {
    type: types.COURSE_ASSIGN_REQ,
    payload: payload,
  };
};
export const updateAclRequest = (data) => {
  return {
    type: types.UPDATE_ACL_REQ,
    payload: data,
  };
};

export const updateAclResponse = (data) => {
  return {
    type: types.UPDATE_ACL_RES,
    payload: data,
  };
};
export const createScormPackage = (data) => {
  return {
    type: types.CREATE_SCORM_PACK,
    payload: data,
  };
};

export const scormPackaged = (data) => {
  return {
    type: types.CREATE_SCORM_PACK_SUCCESS,
    payload: data,
  };
};

export const associateClients = (data) => {
  return {
    type: types.ASSOCIATE_CLIENTS,
    payload: data,
  };
};

export const getLmsSignedUrl = (data) => {
  return {
    type: types.LMS_AUTH_REQUEST,
    payload: data,
  };
};

export const setLmsSignedUrl = (payload) => {
  return {
    type: types.LMS_AUTH_RESPONSE,
    payload,
  };
};

export const addSupplimentaryRequest = (data) => {
  return {
    type: types.ADD_SUPPLIMENTARY_REQ,
    payload: data,
  };
};
export const addConfigVignettesRequest = (data, addToast) => {
  return {
    type: types.ADD_CONFIG_VIGNETTES_REQ,
    payload: {data,addToast}
  };
};
export const addSupplimentaryResponse = (data) => {
  return {
    type: types.ADD_SUPPLIMENTARY_RES,
    payload: data,
  };
};

export const updateSupplimentaryRequest = (data) => {
  return {
    type: types.UPDATE_SUPPLIMENTARY_REQ,
    payload: data,
  };
};

export const updateSupplimentaryResponse = (data, result) => {
  return {
    type: types.UPDATE_SUPPLIMENTARY_RES,
    payload: { data, result },
  };
};

export const allSupplimentaryResponse = (data) => {
  return {
    type: types.SELECTED_SUPPLIMENTARY_RES,
    payload: data,
  };
};

export const allHistoryResponse = (data) => {
  return {
    type: types.SELECTED_HISTORY_RES,
    payload: data,
  };
};

export const selectedHistory = (data) => {
  return {
    type: types.SELECTED_HISTORY_IN_LIST,
    payload: data,
  };
};

export const adminTagsRequest = () => {
  return {
    type: types.ADMIN_TAGS_REQ,
  };
};

export const adminTagsResponse = (data) => {
  return {
    type: types.ADMIN_TAGS_RES,
    payload: data,
  };
};

export const adminTagsUpdate = (data) => {
  return {
    type: types.ADMIN_TAGS_UPDATE,
    payload: data,
  };
};

export const statsDataRequest = (data) => {
  return {
    type: types.STATS_DATA_REQ,
    payload: data,
  };
};

export const statsDataResponse = (data) => {
  return {
    type: types.STATS_DATA_RES,
    payload: data,
  };
};
export const companyDataRequest = (data) => {
  return {
    type: types.COMPANY_DATA_REQ,
    payload: data,
  };
};

export const companyDataResponse = (data) => {
  return {
    type: types.COMPANY_DATA_RES,
    payload: data,
  };
};
export const selectedCompanyDataRequest = (data) => {
  return {
    type: types.SELECTED_COMPANY_DATA_REQ,
    payload: data,
  };
};

export const selectedCompanyDataResponse = (data) => {
  return {
    type: types.SELECTED_COMPANY_DATA_RES,
    payload: data,
  };
};
export const selectedCompanyDataSeats = (data) => {
  return {
    type: types.SELECTED_COMPANY_DATA_SEAT_RES,
    payload: data,
  };
};
export const setCompanyLoader = (check) => {
  return {
    type: types.SET_COMPANY_LOADER_DATA,
    payload: check,
  };
};
export const setClientUserLoader = (check) => {
  return {
    type: types.SET_CLIENTUSER_LOADER_DATA,
    payload: check,
  };
};
export const selectedClientDataRequest = (data) => {
  return {
    type: types.SELECTED_CLIENT_DATA_REQ,
    payload: data,
  };
};

export const selectedClientDataResponse = (data) => {
  return {
    type: types.SELECTED_CLIENT_DATA_RES,
    payload: data,
  };
};


export const selectedClientCoursesDataRequest = (data) => {
  return {
    type: types.SELECTED_CLIENT_COURSES_DATA_REQ,
    payload: data,
  };
};

export const selectedClientCoursesDataResponse = (data) => {
  return {
    type: types.SELECTED_CLIENT_COURSES_DATA_RES,
    payload: data,
  };
};

export const courseCategoryRequest=()=>{
  return {
    type: types.COURSE_CATEGORY_REQ,
  };
}
export const courseCategoryResponse=(data)=>{
  return {
    type: types.COURSE_CATEGORY_RES,
    payload: data,
  };
}
export const userCacheRequest=()=>{
  return {
    type: types.USER_CACHE_REQ,
  };
}
export const userCacheResponse=(data)=>{
  return {
    type: types.USER_CACHE_RES,
    payload: data,
  };
}
export const activeUserCacheRequest=()=>{
  return {
    type: types.ACTIVE_USER_CACHE_REQ,
  };
}
export const activeUserCacheResponse=(data)=>{
  return {
    type: types.ACTIVE_USER_CACHE_RES,
    payload: data,
  };
}

export const courseConfigRequest = (data) => {
  return {
    type: types.COURSE_CONFIG_REQ,
    payload: data,
  };
};

export const courseConfigResponse = (data) => {
  return {
    type: types.COURSE_CONFIG_RES,
    payload: data,
  };
};
export const scromPackageRequest = (data) => {
  return {
    type: types.SCROM_PACKAGE_REQ,
    payload: data,
  };
};

export const scromPackageResponse = (data) => {
  return {
    type: types.SCROM_PACKAGE_RES,
    payload: data,
  };
};
export const languagePackageRequest = (data) => {
  return {
    type: types.LANGUAGE_PACKAGE_REQ,
    payload: data,
  };
};

export const languagePackageResponse = (data) => {
  return {
    type: types.LANGUAGE_PACKAGE_RES,
    payload: data,
  };
};
export const languageConfigRequest = (data) => {
  return {
    type: types.LANGUAGE_CONFIG_REQ,
    payload: data,
  };
};

export const languageConfigResponse = (data) => {
  return {
    type: types.LANGUAGE_CONFIG_RES,
    payload: data,
  };
};


export const setScromLoader = (data) => {
  return {
    type: types.SET_SCROM_LOADER,
    payload: data,
  };
};
export const setStatisticTabData = (data) => {
  return {
    type: types.SET_STATISTIC_TAB_DATA,
    payload: data,
  };
};
export const resetProgramWriter = (data) => {
  return {
    type: types.RESET_PROGRAM_WRITER,
    payload: data,
  };
};
export const setMultilingualConfig = (data) => {
  return {
    type: types.SET_MULTILINGUAL_CONFIG,
    payload: data,
  };
};
// export const setSelectedClientDataReq = (data) => {
//   return {
//     type: types.SET_SELECTED_CLIENT_DATA_REQ,
//     payload: data,
//   };
// };
export const setSelectedClientDataRes = (data) => {
  return {
    type: types.SET_SELECTED_CLIENT_DATA_RES,
    payload: data,
  };
};
export const quizDownloadReq = (data) => {
  return {
    type: types.QUIZ_DOWNLOAD_REQ,
    payload: data,
  };
};
export const quizDownloadRes = (data) => {
  return {
    type: types.QUIZ_DOWNLOAD_RES,
    payload: data,
  };
};
export const textDownloadReq = (data) => {
  return {
    type: types.TEXT_DOWNLOAD_REQ,
    payload: data,
  };
};
export const textDownloadRes = (data) => {
  return {
    type: types.TEXT_DOWNLOAD_RES,
    payload: data,
  };
};
export const courseApprovalReq = (data) => {
  return {
    type: types.SET_COURSE_APPROVAL_REQ,
    payload: data,
  };
};
export const courseApprovalRes = (data) => {
  return {
    type: types.SET_COURSE_APPROVAL_RES,
    payload: data,
  };
};

export const directLoginReq = (data) => {
  return {
    type: types.DIRECT_LOGIN_REQ,
    payload: data,
  };
};
export const directLoginFail = (data) => {
  return {
    type: types.DIRECT_LOGIN_FAILURE,
    payload: data,
  };
};
export const s3ConfigReq = (data) => {
  return {
    type: types.S3_CONFIG_REQ,
    payload: data,
  };
};
export const s3ConfigRes = (data) => {
  return {
    type: types.S3_CONFIG_RES,
    payload: data,
  };
};
export const courseLockReq = (data) => {
  return {
    type: types.COURSE_LOCK_REQ,
    payload: data,
  };
};
export const courseUnlockReq = (data) => {
  return {
    type: types.COURSE_UNLOCK_REQ,
    payload: data,
  };
};
export const courseConfigComment = (data) => {
  return {
    type: types.COURSE_CONFIG_COMMENT,
    payload: data,
  };
};

