import { Box, Button, IconButton, Modal } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';

import React from 'react'

function ModalComment({lockModal,commentTextArea,changeComment,SetLockModal,changeCourseLockStatus}) {
    const styleCommentModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90%",
        maxWidth:"580px",
        minWidth:"360px",
        bgcolor: '#fff',
        border: '1px solid #757575',
        boxShadow: 2,
        borderRadius: 5, 
        p: 4,
      };

  return (
    <Modal open={lockModal?._id} onClose={()=>{
        SetLockModal({});
        commentTextArea.current="";
        }}>
        <Box sx={styleCommentModal}>
        <IconButton sx={{position:"absolute",top:"10px",right:"20px"}} onClick={()=>{
        SetLockModal({});
        commentTextArea.current="";
        }}>
                <CloseIcon />
              </IconButton>
            <form>
                <label style={{color: '#336fe3'}}>ADD COMMENT :</label>
                <textarea onChange={(e)=>{changeComment(e.target.value.trim())}} className='w-100 mt-3 mb-3 comment-modal-text'></textarea>
                <div className='d-flex justify-content-end gap-3 modal-actions'>
                <Button variant="contained" color="primary" onClick={changeCourseLockStatus}>Submit</Button>
                <Button variant="contained" color="secondary" onClick={changeCourseLockStatus}>Cancel</Button>
                </div>
            </form>
        </Box>
    </Modal>  
  )
}

export default ModalComment